<template>
  <v-dialog v-model="active" width="600">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="success" style="margin-right:1em">Add Class</v-btn>
    </template>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Course Search</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-alert color="error" outlined icon="fas fa-exclamation-triangle">Classes with this icon conflict with a course on your schedule.</v-alert>
        <v-alert color="info" outlined icon="fas fa-stop-circle">Classes with this icon are full. You will need to talk to the instructor before joining this class.</v-alert>
        <v-autocomplete v-model="courseToAdd" :items="courses" :loading="loading" :search-input.sync="courseSearch" label="Search for course" item-text="title" item-value="_id" outlined>
          <template v-slot:no-data>
            <v-alert color="error" icon="fal fa-empty-set" style="margin:0" dark>No courses match your search text.</v-alert>
          </template>
          <template v-slot:item="{ item, on }">
            <v-list-item :disabled="item.conflict" v-on="on" style="width:535px">
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
                <v-list-item-subtitle v-if="item.instructor">{{ item.instructor }}</v-list-item-subtitle>
                <v-list-item-subtitle v-if="item.times">{{ item.times }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="item.conflict">
                <v-icon color="error">fas fa-exclamation-triangle</v-icon>
              </v-list-item-action>
              <v-list-item-action v-else-if="item.full">
                <v-icon color="info">fas fa-stop-circle</v-icon>
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-autocomplete>
        <p v-if="isUpperClassman">You can set the grade mode of this course to either be standard grading or pass-fail. Any course toward your major must be standard grading.</p>
        <v-switch v-if="isUpperClassman" v-model="passFail" :label="'Grade Mode: ' + (passFail ? 'Pass-Fail' : 'Standard Grading')"></v-switch>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="active = false">Close</v-btn>
        <v-btn text color="success" @click="add">Add Class</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'
import { checkConflict } from './functions'

export default {
  props: {
    term: {
      type: String,
      required: true
    },
    isUpperClassman: {
      type: Boolean,
      default: false
    },
    schedule: {
      type: Array,
      default: () => { return [] }
    }
  },
  setup (props, { root, emit }) {
    const classService = root.$feathers.service('calendar/classes')

    const active = ref(false)
    const courseSearch = ref('')
    const courseToAdd = ref('')
    const courses = ref([])
    const loading = ref(false)
    const passFail = ref(false)

    const includedCRNs = computed(() => props.schedule.map(({ crn }) => crn))

    watch(active, () => {
      courseSearch.value = ''
      courses.value = []
    })
    watch(courseSearch, (val) => {
      if (val == null) {
        val = ''
      }
      loading.value = true
      classService.find({ query: { term: props.term, title: { $regex: val, $options: 'i' }, crn: { $nin: includedCRNs.value } } }).then(({ data }) => {
        courses.value = []
        data.forEach(({ _id, crn, title, instructors, meetingBase, counts }) => {
          let instructor = ''
          if (instructors.length > 0) {
            instructor = instructors[0].name
          }
          let times = []
          let conflict = false
          if (meetingBase.length > 0) {
            meetingBase.forEach(({ days, startTime, endTime, room }) => {
              if (room != null) {
                times.push(days + ' ' + startTime + ' - ' + endTime + ', ' + room)
                if (checkConflict({ days, startTime, endTime }, props.schedule)) conflict = true
              }
            })
          }
          courses.value.push({ _id, crn, title, instructor, times: times.join('; '), conflict, full: counts.rem === 0 })
        })
      }).catch((e) => {
        console.log(e)
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 10000, text: 'Error loading courses: ' + e })
      }).finally(() => {
        loading.value = false
      })
    })

    function add () {
      emit('add', { id: courseToAdd.value, passFail: passFail.value })
      active.value = false
    }

    return {
      active,
      courseSearch,
      courseToAdd,
      courses,
      loading,
      passFail,
      includedCRNs,
      add
    }
  }
}
</script>
